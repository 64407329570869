import { Col, Grid, Row, Separator } from 'atomic'
import * as React from 'react'
import LargeSeparatorRow from '../../../../atomic/atm.large-separator-row/large-separator-row.component'
import { FeatureItem } from './feature-item.component'

interface FeatureItemSectionProps {
  items: FeatureItem[]
}

const FeatureItemSection: React.FunctionComponent<FeatureItemSectionProps> = props => {
  return (
    <Grid>
      <LargeSeparatorRow />
      <Row mb around="sm" center="xs">
        {props.items.map(item => (
          <Col xs={12} sm={8} mdOffset={0} md={3}>
            <Separator />
            <FeatureItem
              imgSrc={item.imgSrc}
              imgAlt={item.imgAlt}
              title={item.title}
              body={item.body}
            />
            <Separator />
          </Col>
        ))}
      </Row>
      <LargeSeparatorRow />
    </Grid>
  )
}

export default FeatureItemSection
