import styled from 'styled-components'
import { Spacing } from 'atomic'

export const LinkListStyled = styled.div<{ mobile: boolean }>`
  margin-top: ${props =>
    props.mobile
      ? `${parseInt(Spacing.XXLarge, 10) + parseInt(Spacing.Medium, 10)}px`
      : `${Spacing.XLarge}`};
`

export const LinkItemStyled = styled.div`
  margin-bottom: ${Spacing.Large};
`
