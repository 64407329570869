import { Border } from 'atomic'
import ColorFunc from 'color'
import styled from 'styled-components'

export const HowItWorksImageWrapper = styled.div`
  position: relative;
  :before {
    display: block;
    content: '';
    border-radius: ${Border.Radius};
    width: 300%;
    height: calc(100% + 60px);
    position: absolute;
    bottom: -30px;
    right: 30%;
    background-color: ${props =>
      ColorFunc(props.theme.color.primary)
        .alpha(0.1)
        .hsl()
        .string()};
  }
`
