import styled from 'styled-components'

/** https://stackoverflow.com/a/38270745/3670829 */
export const VideoWrapperStyled = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
`

/** https://stackoverflow.com/a/38270745/3670829 */
export const VideoStyled = styled.iframe.attrs({
  width: 16,
  height: 9,
  frameBorder: 0,
  allowFullScreen: true,
  allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
