import * as React from 'react'
import { VideoStyled, VideoWrapperStyled } from './video.component.style'
import QueryString from 'query-string'

interface VideoProps {
  url: string
}

/**
 * Component to display a video (tested with Youtube videos) that fills the width of its container
 * https://stackoverflow.com/a/38270745/3670829
 * @param props
 */
export const Video: React.SFC<VideoProps> = props => {
  return (
    <VideoWrapperStyled>
      <VideoStyled src={getFormattedUrl(props.url)} srcDoc={getSrcDoc(props.url)} />
    </VideoWrapperStyled>
  )
}

/**
 * the Video component needs a url with the `embed` format.
 * This method tries to generate this kind of url
 *
 * @param url
 */
const getFormattedUrl = (url: string) => {
  const id = getVideoId(url)
  const isEmbedUrl = url.includes('/embed/')
  const finalUrl = isEmbedUrl ? url : `https://www.youtube.com/embed/${id}`
  return finalUrl
}

/**
 * https://dev.to/haggen/lazy-load-embedded-youtube-videos-520g
 * @param url
 */
const getSrcDoc = (url: string) => {
  const id = getVideoId(url)
  if (!id) {
    return undefined
  }

  const srcdoc = `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
  <a href=https://www.youtube.com/embed/${id}?autoplay=1><img src=https://img.youtube.com/vi/${id}/hqdefault.jpg><span>▶</span></a>`
  return srcdoc
}

const getVideoId = (url: string) => {
  const params = QueryString.parseUrl(url)
  const id = params.query.v
  return id
}
