import * as React from 'react'
import { Background } from 'react-imgix'
import {
  BackgroundImageAspectRatioWrapper,
  BackgroundImageContentWrapper
} from './background-image.style'

export interface BackgroundImageProps {
  imgSrc: string
  aspectRatio: number
  height?: string
  width?: string
}

const BackgroundImage: React.FunctionComponent<BackgroundImageProps> = props => {
  return (
    <Background src={props.imgSrc}>
      <BackgroundImageAspectRatioWrapper aspectRatio={props.aspectRatio}>
        <BackgroundImageContentWrapper>{props.children}</BackgroundImageContentWrapper>
      </BackgroundImageAspectRatioWrapper>
    </Background>
  )
}

export default BackgroundImage
