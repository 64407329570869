import { Spacing } from 'atomic'
import ColorFunc from 'color'
import styled from 'styled-components'

export const FeatureItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const FeatureItemImgWrapper = styled.div`
  align-content: center;
  margin: auto;
  margin-bottom: ${Spacing.Large};
  border-radius: 50%;
  padding: ${Spacing.Large};
  overflow: hidden;
  background-color: ${props =>
    ColorFunc(props.theme.color.primary)
      .alpha(0.1)
      .hsl()
      .string()};
`
