import styled from 'styled-components'

export const BackgroundImageContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
export const BackgroundImageAspectRatioWrapper = styled.div<{ aspectRatio }>`
  position: relative;
  padding-bottom: ${props => (props.aspectRatio ? `${props.aspectRatio * 100}%` : '')};
`
