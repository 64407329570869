import { Button, Col, Color, Grid, H2, HDisplay, LargeSeparator, Row, Separator } from 'atomic'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import * as React from 'react'
import { ColorBackground } from '../atm.background/background.styled'
import { MultipleCollumnWrapper } from '../atm.wrapper/wrapper.component'
import { LinkItemStyled, LinkListStyled } from './link-list-section.style'

interface LinkListSectionProps {
  title?: string
  category: string
  links: Link[]
  light?: boolean
}

interface Link {
  name: string
  slug: string
}

const LinkListSection: React.FunctionComponent<LinkListSectionProps> = props => (
  <>
    <ColorBackground color={props.light ? Color.White : Color.GrayXDark}>
      <Grid>
        {props.title ? (
          <LinkSideCollumnListRow
            title={props.title}
            category={props.category}
            links={props.links}
            light={props.light}
          />
        ) : (
          <LinkCollumnListRow category={props.category} links={props.links} light={props.light} />
        )}
      </Grid>
    </ColorBackground>
  </>
)

export default LinkListSection

interface LinkCollumnListRowProps {
  category: string
  links: Link[]
  light?: boolean
}

const LinkCollumnListRow: React.FunctionComponent<LinkCollumnListRowProps> = props => (
  <>
    <Row mb>
      <Col xs>
        <LargeSeparator />
        <H2 kind={props.light ? 'default' : 'light'}>{props.category}</H2>
      </Col>
    </Row>
    <Row center="xs">
      <Col xs={12}>
        <Separator />
        <MultipleCollumnWrapper numberOfCollumns={6} numberOfCollumnsMobile={2}>
          {props.links.map((link, index) => (
            <LinkItemStyled>
              <Button
                id={`button-link-section-${index}`}
                kind="link"
                light={!props.light}
                to={link.slug}
              >
                {link.name}
              </Button>
            </LinkItemStyled>
          ))}
        </MultipleCollumnWrapper>
        <LargeSeparator />
      </Col>
    </Row>
  </>
)

interface LinkSideCollumnListRowProps {
  title: string
  category: string
  links: Link[]
  light?: boolean
}

const LinkSideCollumnListRow: React.FunctionComponent<LinkSideCollumnListRowProps> = props => (
  <Row>
    <Col xs={12}>
      <LargeSeparator />
    </Col>
    <Col xs={12} md={3}>
      <H2 kind={props.light ? 'default' : 'light'}>{props.category}</H2>
      <HDisplay kind={props.light ? 'default' : 'light'}>{props.title}</HDisplay>
    </Col>
    <Col xs={12} mdOffset={1} md={8}>
      <MatchMedia defaultMinWidth={`48rem`}>
        {md => (
          <LinkListStyled mobile={!md}>
            <MultipleCollumnWrapper numberOfCollumns={4} numberOfCollumnsMobile={2}>
              {props.links.map((link, index) => (
                <LinkItemStyled>
                  <Button
                    id={`button-name-link-${index}`}
                    kind="link"
                    light={!props.light}
                    to={link.slug}
                  >
                    {link.name}
                  </Button>
                </LinkItemStyled>
              ))}
            </MultipleCollumnWrapper>
          </LinkListStyled>
        )}
      </MatchMedia>
    </Col>
    <Col xs>
      <LargeSeparator />
    </Col>
  </Row>
)
