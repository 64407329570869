import { BodySecondaryCentered, HDisplay } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import * as React from 'react'
import { FeatureItemImgWrapper, FeatureItemStyled } from './feature-item.component.style'

export interface FeatureItem {
  imgSrc: string
  imgAlt: string
  title: string
  body: string
}

export const FeatureItem: React.FunctionComponent<FeatureItem> = props => (
  <FeatureItemStyled>
    <FeatureItemImgWrapper>
      <LazyLoadImage src={props.imgSrc} width={40} alt={props.imgAlt} />
    </FeatureItemImgWrapper>
    <HDisplay center>{props.title}</HDisplay>

    <BodySecondaryCentered>
      <div dangerouslySetInnerHTML={{ __html: props.body }} />
    </BodySecondaryCentered>
  </FeatureItemStyled>
)
