import { NeutralBackground } from '@app/components/atm.background/background.styled'
import {
  BorderRoundWrapper,
  StickButtonWrapper
} from '@app/components/atm.wrapper/wrapper.component'
import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import { CardKind } from '@app/components/mol.card/card.component'
import ReceptiveCampaignHeroImage from '@app/components/mol.receptive-campaign-hero-image/receptive-campaign-hero-image.component'
import { SchedulingModal } from '@app/components/mol.scheduling-modal/scheduling-modal.component'
import { CardsSection } from '@app/components/org.cards-section/cards-section.component'
import FeatureItemSection from '@app/components/org.feature-item-section/feature-item-section.component'
import LinkListSection from '@app/components/org.link-list-section/link-list-section.component'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import { PageProps } from '@app/utils/local-types'
import { appPaths, getCanonicalUrl, getPathUrl, getUnitDetailPageUrl } from 'utils/path'
import { getClientAndDoctorUrl, getSearchQueryParam, isDoctorUrl } from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { Query, CosmicjsReceptivosDeCampanhas } from '@root/src/data/graphql/graphql-types'
import { HowItWorksImageWrapper } from '@root/src/modules/campaign-receptive/component/how-it-works-image-wrapper.style'
import { Button, Col, Grid, H2, Row, Separator } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { Video } from 'atomic/legacy/atm.video/video.component'
import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import { graphql } from 'gatsby'
import React from 'react'

export interface CampaignReceptiveDetailTemplateProps {
  slug: string
  data: CosmicjsReceptivosDeCampanhas
}

const CampaignReceptiveDetailTemplate: React.FunctionComponent<PageProps<
  CampaignReceptiveDetailTemplateProps,
  Query
>> = props => {
  const cosmicData = props.pageContext.data
  const metadata = cosmicData.metadata
  const cosmicStaticInfo = props.data.cosmicjsInformacoesEstaticas.metadata

  const doctorsCards = (metadata.especialistas || []).map(especialista => ({
    imgSrc: especialista.metadata.foto.imgix_url,
    imgAlt: `Foto do especialista ${especialista.title} `,
    title: especialista.title,
    body: especialista.metadata.descricaoSimples,
    linkText: 'Ver perfil',
    linkTo: `${especialista.type_slug}/${especialista.slug}`,
    kind: CardKind.icon
  }))

  const examList = (metadata.exames || []).map(exame => ({
    name: `- ${exame.title}`,
    slug: `${getPathUrl(
      appPaths.search.path,
      isDoctorUrl(props.location.pathname)
    )}${getSearchQueryParam(exame.title)}`
  }))

  const staticInfo = {
    section4Subject: cosmicStaticInfo.tituloSecao4,
    section5Subject: cosmicStaticInfo.assuntoSecao5,
    section5Title: cosmicStaticInfo.tituloSecao5,
    section6Subject: cosmicStaticInfo.assuntoSecao6,
    section6Title: cosmicStaticInfo.tituloSecao6,
    section7Subject: cosmicStaticInfo.assuntoSecao7
  }

  const data = {
    examList,
    staticInfo,
    unitList: metadata.unidades,
    receptiveTitle: cosmicData.title,
    bannerTitle: metadata.tituloBanner,
    bannerImg: metadata.imagemBanner.imgix_url,
    receptiveContent: cosmicData.content,
    videoLink: metadata.urlVideo,
    featureItems: metadata.diferenciais.map(diferencial => ({
      title: diferencial.title,
      body: diferencial.content,
      imgSrc: diferencial.metadata.thumbnail.imgix_url,
      imgAlt: 'Icone do diferencial'
    })),
    howItWorksImage: metadata.comoFuncionaImagem,
    howItWorksContent: metadata.comoFuncionaDescricao,
    doctorCards: doctorsCards
  }
  const seoData = metadata.seo
  const siteUrl = props.data.site.siteMetadata.siteUrl
  const isDoctor = isDoctorUrl(props.location.pathname)

  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <IndexLayout location={props.location} showScheduleButton={false}>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(
              siteUrl,
              appPaths.campaignReceptive.path,
              props.pageContext.slug
            ),
            title: seoData.titulo,
            image: seoData.imagem.url,
            imageAlt: seoData.imagemAlternativa,
            description: seoData.descricao
          }}
        />
        <ReceptiveCampaignHeroImage
          imgSrc={data.bannerImg}
          mobileAspectRatio={0.56}
          webAspectRatio={0.25}
          title={data.bannerTitle}
        />

        <NeutralBackground>
          <Grid>
            <LargeSeparatorRow />
            <Row>
              <Col xs md={7}>
                <div dangerouslySetInnerHTML={{ __html: data.receptiveContent }} />
              </Col>
              <Col mdOffset={1} md={4}>
                <BorderRoundWrapper>
                  <Video url={data.videoLink} />
                </BorderRoundWrapper>
              </Col>
            </Row>
            <LargeSeparatorRow />
          </Grid>
        </NeutralBackground>
        <FeatureItemSection items={data.featureItems} />
        <Grid>
          <LargeSeparatorRow />
          <Row center="sm">
            <Col xs={12} sm={8} md={4}>
              <Separator />
              <HowItWorksImageWrapper>
                <BorderRoundWrapper>
                  <LazyLoadImage
                    src={data.howItWorksImage.imgix_url}
                    alt={'Imagem da seção como funciona'}
                    aspectRatio={0.56}
                    fitWidth
                  />
                </BorderRoundWrapper>
              </HowItWorksImageWrapper>
              <Separator />
            </Col>
            <Col xs={12} mdOffset={1} md={7}>
              <H2>{data.staticInfo.section4Subject}</H2>
              <div dangerouslySetInnerHTML={{ __html: data.howItWorksContent }} />
            </Col>
          </Row>
          <LargeSeparatorRow />
        </Grid>

        {data.doctorCards[0] && (
          <CardsSection
            metadata={{
              sectionTitle: data.staticInfo.section5Subject,
              displayText: data.staticInfo.section5Title,
              buttonText: 'Ver todos especialistas',
              buttonLinkto: getPathUrl(appPaths.ourSpecialists.path, isDoctor)
            }}
            cardsData={data.doctorCards}
            id="campaign"
          />
        )}
        {data.examList[0] && (
          <LinkListSection
            title={data.staticInfo.section6Title}
            category={data.staticInfo.section6Subject}
            links={data.examList}
          />
        )}
        {/* TODO: Change list style */}
        {data.unitList[0] && (
          <LinkListSection
            category={data.staticInfo.section7Subject}
            links={data.unitList.map(item => ({
              name: `- ${item.title}`,
              slug: getUnitDetailPageUrl(getPathUrl(appPaths.units.path, isDoctor), item.slug)
            }))}
            light
          />
        )}

        <BooleanContainer>
          {schedulingModalBool => (
            <>
              <StickButtonWrapper>
                <Button
                  id="button-scheduling-exam-campaign"
                  kind="primary"
                  onClick={schedulingModalBool.setTrue}
                  expanded
                >
                  Agendar exame
                </Button>

                <Separator />
              </StickButtonWrapper>
              <SchedulingModal
                open={schedulingModalBool.value}
                onClose={schedulingModalBool.setFalse}
              />
            </>
          )}
        </BooleanContainer>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default CampaignReceptiveDetailTemplate

export const query = graphql`
  query CampaignReceptiveDetailBySlug {
    cosmicjsInformacoesEstaticas(slug: { eq: "receptivos-de-campanha" }) {
      metadata {
        tituloSecao4
        assuntoSecao5
        tituloSecao5
        assuntoSecao6
        tituloSecao6
        assuntoSecao7
      }
    }
    site {
      ...SiteUrl
    }
  }
`
