import * as React from 'react'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import BackgroundImage from '../atm.background-image/background-image.component'
import { HDisplay, Grid, Row, Col, Body, Separator } from 'atomic'
import { ReceptiveCampaignHeroImgContentWrapper } from './receptive-campaign-hero-image.style'
import { NeutralBackground } from '../atm.background/background.styled'

interface ReceptiveCampaignHeroImageProps {
  imgSrc: string
  mobileAspectRatio: number
  webAspectRatio: number
  title?: string
  subtitle?: string
}

const ReceptiveCampaignHeroImage: React.FunctionComponent<ReceptiveCampaignHeroImageProps> = props => {
  return (
    <>
      <MatchMedia defaultMinWidth={`64rem`}>
        {md => (
          <BackgroundImage
            imgSrc={props.imgSrc}
            aspectRatio={md ? props.mobileAspectRatio : props.webAspectRatio}
          >
            <ReceptiveCampaignHeroImgContentWrapper>
              <Grid>
                <Row>
                  <Col md={4}>
                    {!md && props.title && <HDisplay kind="light">{props.title}</HDisplay>}
                    {!md && props.subtitle && <Body kind="light">{props.subtitle}</Body>}
                  </Col>
                </Row>
              </Grid>
            </ReceptiveCampaignHeroImgContentWrapper>
          </BackgroundImage>
        )}
      </MatchMedia>
      <NeutralBackground>
        <Grid>
          <Row>
            <Col xs={12} sm={false}>
              <Separator />
              <HDisplay>{props.title}</HDisplay>
            </Col>
          </Row>
        </Grid>
      </NeutralBackground>
    </>
  )
}

export default ReceptiveCampaignHeroImage
